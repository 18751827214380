import React, { createContext } from "react"

const defaultState = {
  activeCategoryId: "",
  setActiveCategoryId: () => {},
  activeFiletypeId: "",
  setActiveFiletypeId: () => {},
  searchQuery: "",
  setSearchQuery: () => {},
}

export const ImageLibraryContext = createContext(defaultState)

class ImageLibraryProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeCategoryId: "",
      setActiveCategoryId: this.setActiveCategoryId,
      activeFiletypeId: "",
      setActiveFiletypeId: this.setActiveFiletypeId,
      searchQuery: "",
      setSearchQuery: this.setSearchQuery,
    }
  }

  setActiveCategoryId = id => {
    this.setState({ activeCategoryId: id })
  }

  setActiveFiletypeId = id => {
    this.setState({ activeFiletypeId: id })
  }

  setSearchQuery = query => {
    this.setState({ searchQuery: query })
  }

  render() {
    return (
      <ImageLibraryContext.Provider value={this.state}>
        {this.props.children}
      </ImageLibraryContext.Provider>
    )
  }
}

export default ImageLibraryContext

export { ImageLibraryProvider }
